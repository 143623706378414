import React from 'react'
// react component for creating dynamic tables
import Table from './Table'
import ExtendedTable from './ExtendedTable'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// @material-ui/icons
import Assignment from '@material-ui/icons/Assignment'
import Close from '@material-ui/icons/Close'

import CircularProgress from '@material-ui/core/CircularProgress'
// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import MaterialButton from '@material-ui/core/Button'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CardIcon from 'components/Card/CardIcon.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'

import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx'

import helpers from 'misc/helpers'
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'

import QuestionsAnswered from 'components/Questions'


const { to, post } = helpers


const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  },
  excelDownload: {
    marginTop: '-19px',
    width: '62px',
    height: '58px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0'
  }
}

function Transition(props) {
  return <Slide direction="up" {...props} />
}

class Results extends React.Component {

  state = {
    data: [],
    answeredQuestions: null,
    modalOpen: false,
    userName: '',
    answers: []
  }

  getAnswerHistory = (userName, answers) => {
    this.setState({
      modalOpen: true,
      answers,
      userName
    })
  }


  constructor(props) {
    super(props)
    this.state = {
      results: [],
      data: [],
      downloadingExcel: false
    }
  }



  render() {
    const { classes } = this.props
    return (
      <React.Fragment >
        <GridContainer >
          <GridItem xs={12} >
            <Card >
              <CardHeader color="primary" icon >
                <CardIcon color="primary" >
                  <Assignment />
                </CardIcon >
               <Button disabled={this.state.downloadingExcel}  color={'rose'} style={styles.excelDownload}
                        onClick={() => {
                          this.setState({
                            downloadingExcel: true
                          })
                          const headers = new Headers()
                          headers.append('Authorization', `Bearer ${localStorage.getItem('jwt_token')}`)
                          fetch(process.env['REACT_APP_HOST_' + process.env.NODE_ENV.toUpperCase()] + '/api/tests/export', {
                            method: 'GET',
                            headers
                          })
                            .then(response => response.blob())
                            .then(blob => {
                              this.setState({
                                downloadingExcel: false
                              })
                              const url = window.URL.createObjectURL(blob)
                              let a = document.createElement('a')
                              a.href = url
                              a.download = 'export.xlsx'
                              document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
                              a.click()
                              a.remove()  //afterwards we remove the element again
                            }).catch((e) => {
                            this.setState({
                              downloadingExcel: false
                            })
                          })

                        }} >

                  {this.state.downloadingExcel ?
                    <div >
                      <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <CircularProgress style={{ width: '30px', height: '30px', margin: 0}}/>
                      </div >
                    </div > :
                    <img src={'/excel.png'} style={{ width: '30px', '&:hover': 'cursor' }}
                         alt={'Экспортировать в эксель'}
                         title={'Экспортировать в эксель'} />
                  }
                </Button >
                <h4 className={classes.cardIconTitle} >Результаты тестирований</h4 >
              </CardHeader >
              <CardBody >

                <ExtendedTable />


              </CardBody >
            </Card >
          </GridItem >
        </GridContainer >

        <Dialog
          fullScreen
          open={this.state.modalOpen}
          onClose={() => this.setState({ modalOpen: false, answers: [] })}
          TransitionComponent={Transition}
        >
          <AppBar style={{ position: 'relative' }} >
            <Toolbar >
              <IconButton color="inherit" onClick={() => this.setState({ modalOpen: false, answers: [] })}
                          aria-label="Close" >
                <Close />
              </IconButton >
              <Typography variant="h6" color="inherit" style={{ flex: 1 }} >
                Закрыть
              </Typography >
              <MaterialButton color="inherit"
                              onClick={() => this.setState({ modalOpen: false, answers: [] })} >
                {this.state.answers ? this.state.userName : ''}
              </MaterialButton >
            </Toolbar >
          </AppBar >
          <div style={{ position: 'relative', height: '100%' }} >
            {!this.state.answers ?
              <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                <CircularProgress />
              </div > :
              <QuestionsAnswered answers={this.state.answers} />}
          </div >
        </Dialog >
      </React.Fragment >
    )
  }
}

export default withStyles(styles)(Results)
