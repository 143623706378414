import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Redirect } from "react-router-dom";
import helpers from "misc/helpers";
import { Formik } from "formik";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, "Слишком короткий!")
    .required("Required"),
  email: Yup.string()
    .email("Неправильный email")
    .required("Required"),
});

const { to, post } = helpers;

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
    };
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={LoginSchema}
              onSubmit={async (values, actions) => {
                const { email, password } = values;

                let [err, done] = await to(
                  post(
                    process.env[
                      "REACT_APP_HOST_" + process.env.NODE_ENV.toUpperCase()
                    ] + "/api/user/login",
                    {
                      email,
                      password,
                    }
                  )
                );

                if (!err) {
                  localStorage.setItem("jwt_token", done.token);
                  window.location.replace("/tests");
                } else {
                  console.log(err);
                }
                actions.setSubmitting(false);
              }}
              render={(props) => {
                console.log(props);
                return (
                  <form>
                    <Card login className={classes[this.state.cardAnimaton]}>
                      <CardHeader
                        className={`${classes.cardHeader} ${
                          classes.textCenter
                        }`}
                        color="rose"
                      >
                        <h4 className={classes.cardTitle}>Вход</h4>
                      </CardHeader>
                      <CardBody>
                        <CustomInput
                          labelText="Email..."
                          id="email"
                          name="email"
                          error={props.errors.email}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Email className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            onChange: props.handleChange,
                            ...props,
                            value: props.values.email,
                          }}
                        />
                        <CustomInput
                          labelText="Пароль"
                          id="password"
                          name="password"
                          type={"password"}
                          error={props.errors.password}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          onChange={props.handleChange}
                          inputProps={{
                            type: "password",
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon className={classes.inputAdornmentIcon}>
                                  lock_outline
                                </Icon>
                              </InputAdornment>
                            ),
                            onChange: props.handleChange,
                            ...props,
                            value: props.values.password,
                          }}
                        />
                      </CardBody>
                      <CardFooter className={classes.justifyContentCenter}>
                        <Button
                          onClick={props.submitForm}
                          disabled={!props.isValid}
                          color="rose"
                          simple
                          size="lg"
                          block
                        >
                          Войти
                        </Button>
                      </CardFooter>
                    </Card>
                  </form>
                );
              }}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(loginPageStyle)(LoginPage);
