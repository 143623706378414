import React from "react";
import ReactDOM from "react-dom";
import jwt  from 'jsonwebtoken';
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import indexRoutes from "routes/index.jsx";

import "assets/scss/material-dashboard-pro-react.css?v=1.4.0";

const hist = createBrowserHistory();
window.addEventListener('storage', function(e) {
  if(!localStorage.getItem('jwt_token')) {
    window.location.href = '/pages/login-page';
  }
}, false);
ReactDOM.render(
  <Router history={hist}>
    <Switch>
      {indexRoutes.map((prop, key) => {

        if(prop.protected){
            console.log(localStorage.getItem('jwt_token'))
            if(localStorage.getItem('jwt_token')){
                const token = jwt.decode(localStorage.getItem('jwt_token'), {complete: true});
                if(!(new Date(token.payload.exp * 1000) < new Date())) {
                    return <Route path={prop.path} component={prop.component} key={key} />;
                } else {
                  localStorage.removeItem('jwt_token')
                  return <Redirect to={'/pages/login-page'}/>
                }
            } else {
                return <Redirect to={'/pages/login-page'}/>
            }
        }else {
            return <Route path={prop.path} component={prop.component} key={key}/>;
        }
      })}
    </Switch>
  </Router>,
  document.getElementById("root")
);
