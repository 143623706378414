import React, { Component } from "react";
import Card from "components/Card/Card.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Grid from "@material-ui/core/Grid/Grid";
import GridItem from "components/Grid/GridItem.jsx";
import styled from "styled-components";
import helpers from "misc/helpers";
import swal from "sweetalert";
import Table from "components/Table/Table.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";

const { to, fetchAsync, post } = helpers;

const Container = styled.div`
  padding: 1em;
`;

class Test extends Component {
  state = {
    tests: [],
    open: false,
    chosenDepartment: null,
    syncingQuestions: false,
    employeeSynced: false,
    syncingEmployees: false,
    totalGeneral: 25,
    questionsNumber: {},
    oldCode: "",
    code: ""
  };

  componentDidMount() {
    const headers = new Headers();
    headers.append(
      "Authorization",
      `Bearer ${localStorage.getItem("jwt_token")}`
    );

    fetch(
      process.env["REACT_APP_HOST_" + process.env.NODE_ENV.toUpperCase()] +
        "/api/tests/get/short",
      {
        method: "GET",
        headers
      }
    )
      .then(reponseJson => reponseJson.json())
      .then(tests => {
        this.setState({
          tests
        });
      })
      .catch(e => {
        console.log(e);
      });

    fetch(
      process.env["REACT_APP_HOST_" + process.env.NODE_ENV.toUpperCase()] +
        "/api/employee/loaded",
      {
        method: "GET",
        headers
      }
    )
      .then(reponseJson => reponseJson.json())
      .then(result => {
        this.setState({
          employeeSynced: result
        });
      })
      .catch(e => {
        window.location.href = "/pages/login-page";
        console.log(e);
      });

    fetch(
      process.env["REACT_APP_HOST_" + process.env.NODE_ENV.toUpperCase()] +
        "/api/code/get",
      {
        method: "GET",
        headers
      }
    )
      .then(reponseJson => reponseJson.json())
      .then(({ code }) => {
        this.setState({
          oldCode: code.value,
          code: code.value
        });
      })
      .catch(e => {
        window.location.href = "/pages/login-page";
        console.log(e);
      });
  }

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  createTest = async () => {
    if (this.state.chosenDepartment) {
      const body = {
        department: {
          name: this.state.chosenDepartment.name,
          id: this.state.chosenDepartment["_id"]
        },
        questions: {
          generalQuestions: this.state.totalGeneral,
          professions: [
            ...Object.keys(this.state.questionsNumber).map(key => {
              return {
                name: key,
                number: this.state.questionsNumber[key]
              };
            })
          ]
        }
      };

      const [err, loaded] = await to(
        post(
          process.env["REACT_APP_HOST_" + process.env.NODE_ENV.toUpperCase()] +
            "/api/tests/create",
          body
        )
      );

      if (!err) {
        swal("Создано!", "Тест был успешно добавлен", "success");
        this.handleClose();
        this.setState({
          chosenDepartment: null,
          totalGeneral: 25,
          questionsNumber: {}
        });
      }
    } else {
      swal("Ошибка!", "Сначала выберите департамент", "error");
    }
  };

  loadEmployees = () => {
    this.setState({
      syncingEmployees: true
    });

    const headers = new Headers();
    headers.append(
      "Authorization",
      `Bearer ${localStorage.getItem("jwt_token")}`
    );

    fetch(
      process.env["REACT_APP_HOST_" + process.env.NODE_ENV.toUpperCase()] +
        "/api/employee/sync",
      {
        method: "GET",
        headers
      }
    )
      .then(resJSON => resJSON.json())
      .then(res => {
        this.setState({
          syncingEmployees: false,
          employeeSynced: true
        });
        swal("Загружено!", "Загрузилось!", "success");
      })
      .catch(e => {
        swal("Ошибка!", "Произошла ошибка при загрузке!", "error");
      });
  };

  loadQuestions = () => {
    this.setState({
      syncingQuestions: true
    });

    const headers = new Headers();
    headers.append(
      "Authorization",
      `Bearer ${localStorage.getItem("jwt_token")}`
    );

    fetch(
      process.env["REACT_APP_HOST_" + process.env.NODE_ENV.toUpperCase()] +
        "/api/questions/sync",
      {
        method: "GET",
        headers
      }
    )
      .then(responseJSON => responseJSON.json())
      .then(res => {
        fetch(
          process.env["REACT_APP_HOST_" + process.env.NODE_ENV.toUpperCase()] +
            "/api/tests/get/short",
          {
            method: "GET",
            headers
          }
        )
          .then(responseJSON => responseJSON.json())
          .then(res => {
            this.setState({
              tests: res,
              syncingQuestions: false
            });

            swal("Загружено!", "Загрузилось!", "success");
          })
          .catch(e => {
            swal("Ошибка!", "Произошла ошибка при загрузке!", "error");
          });
      })
      .catch(e => {
        swal("Ошибка!", "Произошла ошибка при загрузке!", "error");
      });
  };

  updateCode = () => {
    const headers = new Headers();
    headers.append(
      "Authorization",
      `Bearer ${localStorage.getItem("jwt_token")}`
    );
    headers.append("Content-Type", "application/json");

    fetch(
      process.env["REACT_APP_HOST_" + process.env.NODE_ENV.toUpperCase()] +
        "/api/code/update",
      {
        method: "POST",
        headers,
        body: JSON.stringify({
          value: this.state.code
        })
      }
    )
      .then(resJSON => resJSON.json())
      .then(res => {
        swal("Создано!", "Код был успешно обновлен", "success");
        this.setState({
          oldCode: this.state.code
        });
      })
      .catch(e => {
        swal("Ошибка!", "Произошла ошибка при удалении", "error");
      });
  };

  dropDB = () => {
    const header = new Headers();

    header.append(
      "Authorization",
      `Bearer ${localStorage.getItem("jwt_token")}`
    );

    fetch(
      process.env["REACT_APP_HOST_" + process.env.NODE_ENV.toUpperCase()] +
        "/api/questions/remove",
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: header
      }
    )
      .then(response => response.json())
      .then(res => {
        this.setState({
          employeeSynced: false,
          syncingQuestions: false,
          tests: []
        });
        swal("Успешно!", "База удалена", "success");
      })
      .catch(e => {
        console.log(e);
        swal("Ошибка!", "Произошла ошибка", "error");
      }); // parses response to JSON
  };

  render() {
    return (
      <React.Fragment>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <Container>
                <Grid
                  container
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      this.state.tests.length > 0 || this.state.syncingQuestions
                    }
                    onClick={() => this.loadQuestions()}
                  >
                    {this.state.syncingQuestions ? (
                      <CircularProgress />
                    ) : (
                      "Загрузить базу вопросов"
                    )}
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      !this.state.tests.length ||
                      (this.state.employeeSynced || this.state.syncingEmployees)
                    }
                    onClick={() => this.loadEmployees()}
                  >
                    {this.state.syncingEmployees ? (
                      <CircularProgress />
                    ) : (
                      "Загрузить базу сотрудников"
                    )}
                  </Button>

                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={
                      !(
                        this.state.employeeSynced && this.state.tests.length > 0
                      )
                    }
                    onClick={() => this.dropDB()}
                  >
                    Очистить базу
                  </Button>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  style={{ marginTop: "1em" }}
                >
                  <TextField
                    id="standard-name"
                    label="Код тестирования"
                    value={this.state.code}
                    onChange={e => this.setState({ code: e.target.value })}
                    margin="normal"
                  />

                  <Button
                    onClick={this.updateCode}
                    disabled={this.state.oldCode == this.state.code}
                    color={"primary"}
                    style={{ marginLeft: "1em" }}
                    variant={"contained"}
                  >
                    Обновить
                  </Button>
                </Grid>
              </Container>
            </Card>
          </GridItem>

          <GridItem xs={12} style={{ marginTop: "3em" }}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Assignment />
                </CardIcon>
                <h4>Созданные тесты</h4>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="primary"
                  tableHead={["Департамент"]}
                  tableData={[...this.state.tests.map(test => [test.name])]}
                  coloredColls={[1]}
                  colorsColls={["primary"]}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Создание теста</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ marginBottom: "1em" }}>
              Для создания теста - выберите департамент и укажите сколько
              вопросов должно использоваться по каждой категории.
            </DialogContentText>

            <Grid
              container
              direction="column"
              justify="space-around"
              alignItems="center"
            >
              <FormControl style={{ marginBottom: "1em" }}>
                <InputLabel htmlFor="department">Департамен</InputLabel>
                <Select
                  style={{ width: "300px" }}
                  value={
                    !this.state.chosenDepartment
                      ? ""
                      : this.state.chosenDepartment.name
                  }
                  onChange={async e => {
                    const departmentObject = this.state.tests.find(
                      department => department.name === e.target.value
                    );

                    await this.setState({
                      questionNumber: {}
                    });

                    departmentObject.professions.forEach(profession => {
                      console.log(profession);
                      this.setState({
                        questionsNumber: {
                          ...this.state.questionsNumber,
                          [profession.name]: 25
                        }
                      });
                    });

                    this.setState({
                      chosenDepartment: this.state.tests.find(
                        dep => dep.name === e.target.value
                      )
                    });
                  }}
                  inputProps={{
                    name: "department",
                    id: "department"
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {this.state.tests.map((department, i) => (
                    <MenuItem value={department.name} key={i}>
                      {department.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              container
              direction="column"
              justify="space-around"
              alignItems="center"
            >
              {this.state.chosenDepartment
                ? this.state.chosenDepartment.professions.map(
                    (profession, i) => (
                      <GridItem xs={12} style={{ width: "100%" }} key={i}>
                        <TextField
                          key={i}
                          style={{ width: "100%" }}
                          label={profession.name}
                          value={this.state.questionsNumber[profession.name]}
                          onChange={e =>
                            this.setState({
                              questionsNumber: {
                                ...this.state.questionsNumber,
                                [profession.name]:
                                  e.target.value <= 25 && e.target.value > 0
                                    ? Number.parseInt(e.target.value)
                                    : 25
                              }
                            })
                          }
                          type="number"
                          margin="normal"
                        />
                      </GridItem>
                    )
                  )
                : null}

              <GridItem xs={12} style={{ width: "100%" }}>
                <TextField
                  style={{ width: "100%" }}
                  label={"Общие вопросы"}
                  value={this.state.totalGeneral}
                  onChange={e =>
                    this.setState({
                      totalGeneral:
                        e.target.value <= 25 && e.target.value > 0
                          ? Number.parseInt(e.target.value)
                          : 25
                    })
                  }
                  type={"value"}
                  margin="normal"
                />
              </GridItem>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Отмена
            </Button>
            <Button onClick={this.createTest} color="primary">
              Создать
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default Test;
