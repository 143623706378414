import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from '@material-ui/core/Card'
import Chip from '@material-ui/core/Chip'
import styled from 'styled-components'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import CheckCircle from '@material-ui/icons/CheckCircle'

const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`

const Wrapper = styled.div`
    flex-grow: 10;
    display: flex;
    width: 100%;
`


const Question = styled(Row)`
  flex-grow: 1;
  padding-top: 4em;
`

const Answers = styled(Row)`
  flex-grow: 2;
  margin-top: 3em;
`

const QuestionCard = styled(Card)`
  padding: 2em 1.5em;
  
  h3{
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
  }

  @media all and (max-width: 768px){

    h3{
      font-size: 1.3em;
    }

  }
`
const AnswerCard = styled(Card)`
  padding: 1em 1.5em;
  max-height: 300px;
  -webkit-transition: 300ms ease-in-out;
  -moz-transition: 300ms ease-in-out;
  -ms-transition: 300ms ease-in-out;
  -o-transition: 300ms ease-in-out;
  transition: 300ms ease-in-out;

  @media all and (max-width: 768px){

    margin: 2em 0;

  }

  &:hover  {
    color: #008f91;
    cursor: pointer;
  }

  &.active{
    background: #008f91 !important;
    color: white !important;
    cursor: pointer;
    
    div{
      color: white !important;
    }
  }

  &.timeIsOver {
    background: lightgray;
    cursor: default !important;
  }

`

const AnswerContanier = styled.div`
  position: relative;
  width: 100%;
  max-height: 300px;
  
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;

  &:first-letter{
    text-transform: uppercase;
  }
`

const Questions = props => {
  return (
    <Wrapper >
      <Container >
        {props.answers.map( answer => {



          return (
            <Row >
              <Content >
                <Question>
                  <Col xs={{ span: 12 }} >
                    <Chip style={{ marginBottom: '1em', fontFamily: '400'}}
                          label={answer.type === 'general' ? 'Общий' : 'По профессии'}
                          color={answer.type === 'profession' ? 'primary' : 'secondary'} />
                    <QuestionCard >
                      <h3 >{answer.question.name}</h3 >
                    </QuestionCard >
                  </Col >
                </Question>

                <Answers >
                  {Object.keys(answer.question.answers).map((questionAnswer, i) => {
                    return <Col xs={12} md={4} key={i} >
                      <AnswerCard
                        className={[ i == answer.selectedIndex  ? 'active' : ''].join(' ')} >
                        <PerfectScrollbar style={{ maxHeight: '200px' }} >
                          <AnswerContanier >
                            { i == (answer.question.answerIndex - 1) ? <CheckCircle style={{marginRight: '.5em'}} /> : null}
                            {answer.question.answers[questionAnswer]}
                          </AnswerContanier >
                        </PerfectScrollbar >
                      </AnswerCard >
                    </Col >
                  })}
                </Answers >

              </Content >
            </Row >
          )


        })}

      </Container >
    </Wrapper >
  )
}


export default Questions
