import React, { memo } from 'react'
import ReactTable from 'react-table'

const Table = memo(({ data }) => {
    return (
      <ReactTable
        data={data}
        filterable
        previousText='Предыдущий'
        nextText='Следующий'
        loadingText='Загрузка...'
        noDataText='Ничего не найдено'
        rowsText='строчек'
        columns={[
          {
            Header: 'Итого',
            accessor: 'age',
            minWidth: 80
          },
          {
            Header: 'Статус',
            accessor: 'passed',
            minWidth: 105
          },
          {
            Header: 'ФИО',
            accessor: 'name',
            minWidth: 200
          },
          {
            Header: 'Должность',
            accessor: 'position',
            minWidth: 200
          },
          {
            Header: 'Департамент',
            accessor: 'office',
            minWidth: 200
          },
          {
            Header: 'Дата',
            accessor: 'date',
            minWidth: 90
          },
          {
            Header: 'Начало теста',
            accessor: 'startDate',
            minWidth: 90
          },
          {
            Header: 'Конец теста',
            accessor: 'endDate',
            minWidth: 90
          },
          {
            Header: 'Продолжительность',
            accessor: 'duration',
            minWidth: 200
          },
          {
            Header: 'Правильных ответов',
            accessor: 'rightAnswers',
            minWidth: 200
          },
          {
            Header: 'Общие вопросы',
            accessor: 'generalQuestions',
            minWidth: 200
          },
          {
            Header: 'Профессиональные вопросы',
            accessor: 'professionalQuestions',
            minWidth: 200
          },
          {
            Header: 'Неотвеченные вопросы',
            accessor: 'notFilled',
            minWidth: 200
          },
          {
            Header: 'IP адрес',
            accessor: 'ip',
            minWidth: 200
          },


          {
            Header: 'Удалить',
            accessor: 'actions',
            sortable: false,
            filterable: false
          }
        ]}
        defaultPageSize={10}

        showPaginationBottom={true}
        className="-striped -highlight"
      />

    )
  }
)

export default Table
